import { Flex, Avatar, Title, Alert } from '@mantine/core';
import styles from '../../assets/styles/components/welcomebox.module.css';
import useProfile from '../../hooks/use-profile';
import { useNavigate } from 'react-router-dom';
import { formatName, greetUser, initials } from '../../utils';
import { getDicebearProfileInititals } from '../../utils/integration/dicebear';
import mantineConfig from '../../assets/styles/config/mantine.config.json';
import Cookies from 'js-cookie';
import { IconAlertCircle } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WelcomeBox = () => {
	const { user } = useProfile();
	const navigate = useNavigate();
	const { t } = useTranslation(['default']);
	const userInitials = initials({
		firstName: user?.firstName || 'C',
		surName: user?.surName || 'C',
	});
	const profilePhoto =
		Cookies.get('profilePhoto') == null
			? getDicebearProfileInititals(
					initials({
						firstName: user?.firstName || '',
						surName: user?.surName || '',
					})
				) || ''
			: Cookies.get('profilePhoto');

	const nationalIdExists = true; // Change as necessary

	const handleGetStartedClick = (e) => {
		e.preventDefault();
		if (nationalIdExists) {
			navigate('/profile', { state: { tab: 'Billing-Info' } });
		}
	};

	// function t(arg0: string): React.ReactNode {
	// 	throw new Error('Function not implemented.');
	// }

	return (
		<div className={styles['c-welcome']}>
			<Flex direction="column">
				<div className={styles['c-welcome-profile']}>
					<Flex direction="column">
						<Title
							color={mantineConfig.mantine.title.heading.welcome.color}
							style={{
								fontFamily: mantineConfig.mantine.global.fontFamily,
								fontSize:
									mantineConfig.mantine.title.heading.subheading.fontSize,
							}}
							className={styles['c-welcome-tag']}
						>
							{greetUser()}
						</Title>
						<Title
							color={mantineConfig.mantine.title.heading.welcome.color}
							style={{
								fontFamily: mantineConfig.mantine.global.fontFamily,
								fontSize: mantineConfig.mantine.title.heading.fontSize,
							}}
							className={styles['c-welcome-name']}
						>
							{user?.profileComplete &&
								formatName({ title: user?.title, surName: user?.surName })}
						</Title>
					</Flex>
					<Flex gap="1rem" align="center">
						<div
							onClick={handleGetStartedClick}
							style={{ cursor: 'pointer', width: '100%' }}
						></div>
					</Flex>
					<Flex gap="1rem" align="center">
						{user && (
							<Avatar
								onClick={() => navigate('/profile')}
								className={styles['c-welcome-avatar']}
								src={profilePhoto}
								alt={
									getDicebearProfileInititals(
										initials({
											firstName: user?.firstName,
											surName: user?.surName,
										})
									) || ''
								}
								aria-label={userInitials || ''}
								title={userInitials || ''}
								onError={(e) => {
									const target = e.currentTarget;
									target.src = `data:image/svg+xml;base64,${btoa(
										getDicebearProfileInititals(
											initials({
												firstName: user?.firstName,
												surName: user?.surName,
											})
										)
									)}`;
								}}
								sx={{
									cursor: 'pointer',
									transition: 'transform 0.2s',
									width: '50px', // Adjusted to a smaller size
									height: '50px', // Adjusted to a smaller size
									borderRadius: '50%', // Ensures it's round
									'&:hover': {
										transform: 'scale(1.05)', // Slightly enlarge on hover
									},
								}}
							/>
						)}
					</Flex>
				</div>
			</Flex>
			<Alert
				// icon={<IconAlertCircle size="xl" />}
				// title="Carelyo Wallet"
				color={mantineConfig.mantine.alert.color}
				radius="md"
				variant="outline"
				align="center"
				style={{
					padding: '1rem',
					fontSize: '1rem',
					maxWidth: '600px',
					margin: '0 auto',
					textAlign: 'center', // Ensure the alert content is centered
				}}
			>
				<p
					style={{
						fontFamily: mantineConfig.mantine.global.fontFamily,
						fontSize: '0.9rem',
						lineHeight: '1.5',
						margin: '0.5rem 0',
					}}
				>
					{t('tr.news-nofication-one')}
					{t('tr.news-nofication-two')}
				</p>
				<p
					style={{
						fontFamily: mantineConfig.mantine.global.fontFamily,
						fontSize: '1.25rem', // Larger font size
						fontWeight: 'bold', // Make it bold
						lineHeight: '1.5',
						margin: '0.5rem 0',
					}}
				>
					{t('tr.news-nofication-three')}
				</p>
			</Alert>
		</div>
	);
};

export default WelcomeBox;
